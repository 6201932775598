<template>
    <div>

        <div class="d-flex justify-content-between align-items-center mb-n2">
      <h4 style="font-weight: 500">Rôles</h4>
      <mdb-btn
        class="float-right"
        tag="a"
        color="primary"
        icon="plus"
        size="sm"
        small
        @click.native="addOrEdit(null)"
      >
      </mdb-btn>
    </div>
    <p style="font-weight: 400 ;">
        Personnalisez les autorisations en quelques étapes simples : cliquez sur le rôle désiré, <br> puis cochez/décochez les cases pour ajuster les permissions selon vos besoins, sauf pour ces deux premiers rôles (Administrateur ou Utilisateur)  <br> qui sont fixes.
    </p>
    <hr class="" />
    <div class="card" style="height: calc(100vh - 88px - 24px - 126px);">
        <div class=" role-view">
            <div class="role-view__list">
                <ul>
                    <li  @click="selectRole(role.id)"   :class="[role.id === roleSelected && 'is-active']"  
                        v-for="(role,index) in dataRole.rows.filter(element => element.company_id === null)" :key="index">
                        <div>
                            <a style="font-weight: 400;text-transform: capitalize;">{{ role.name }}  </a>  <small>(par défaut)</small>
                        </div>
                        <div>
                            
                        </div>
                    </li>
                    <li @click="selectRole(role.id)"   :class="[role.id === roleSelected && 'is-active']"   class="" 
                        v-for="(role,index) in dataRole.rows.filter(element => element.company_id !== null)" :key="index">
                        <a style="font-weight: 400;text-transform: capitalize;">{{ role.name }}</a>  
                        <div>
                            <button @click="addOrEdit(role)" style="background-color: transparent; border:none;color:inherit;transition:all .3s"><i   class="fas fa-pen" style="font-size: .70rem;margin-left:.4rem"></i> </button>
                            <button @click="deleteRole(role.id)" style="background-color: transparent; border:none;color:inherit;transition:all .3s"><i   class="fas fa-trash" style="font-size: .70rem;margin-left:.4rem"></i> </button>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="role-view__list-details" style="padding-top: 50px;width:auto">
                <div class="table-dh56265656">
                    <table class="" >
                        <tr class="dh56265656">
                            <td class="checkingbox">
                                <div class="custom-checkbox">
                                    <!-- <input class="chc" type="checkbox" :checked="permissionComp.length === permissionsSelected.length" > -->
                                    <!-- <span class="checkmark"></span> -->
                                </div>
                            </td>
                            <td class="labelbox" style="font-weight: 500;">{{ permissionsSelected.length }} Permissions</td>
                        </tr>
                    </table>
                </div>
                <table  style="min-height: 10vh;">
                         <template v-for="(item, index)  in dataPermission.rows">
                        <tr :key="index" >
                            <td colspan="2" style="font-weight: 500;"> {{index}}</td>
                        </tr>
                        <tr :key="index2+index" v-for="(item2, index2) in item" >
                            <td class="checkingbox"> 
                                <div class="custom-checkbox" @click="setChecking(item2.id)">
                                    <input class="chc" type="checkbox" :checked="permissionsSelected.includes(item2.id)" :disabled="hasDefaultRole" >
                                    <span class="checkmark"></span>
                                </div>
                            </td>
                            <td class="labelbox">
                                <div style="">{{ item2.name }}</div>
                                <p style="margin-bottom: 0;font-weight: 300;">{{ item2.description }}</p>
                            </td>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>
    <mdb-modal
        centered
        size="md" 
        direction="top"
        position="top"
        :show="modalRole.show" 
    >
        <form @submit.prevent='modalRole.edit ? updateRole() : saveRole()'>
        <mdb-modal-header>
            <mdb-modal-title>{{ modalRole.title }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body class="modal-tec card-reversed color-normal-reversed">
                <fieldset>
                    <mdb-row>
                        <mdb-col sm="12" md="12">
                            <mdb-input wrapperClass="" inputClass="border-reversed color-normal-reversed" labelClass=" color-normal-reversed" 
                             :validation="$store.state.role.errors.name ? true :false" :invalidFeedback="$store.state.role.errors.name"
                                v-model="form.name"
                                @change="nameFormatter"
                              size="lg" outline label="Désignation" ></mdb-input>
                        </mdb-col>
                        
                    </mdb-row>
                </fieldset>
        </mdb-modal-body>
            <mdb-modal-footer class="">
                <mdb-btn size="md" color="primary" type="submit" :disabled="modalRole.btn">
                    <span v-if="!modalRole.btn">Sauvegarder</span>
                    <span v-if="modalRole.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalRole.btn" class="pl-2">Loading...</span>
                </mdb-btn>
                <mdb-btn size="md" outline="primary" @click="modalRole.show = false">Fermer</mdb-btn>

            </mdb-modal-footer>
        </form>
    </mdb-modal>
    </div>

</template>

<script>
import {
  mdbBtn,
  mdbInput,
  mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
} from 'mdbvue'
export default {
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - Roles',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbBtn,
        mdbInput,
        mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
    },

    data(){
        return {
            dataPagination: {
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"10",value:"10", selected: true},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",
                propsToSearch: [
                    "firstname",
                    "lastname",
                    "email",
                    "phone",
                    "site"
                ],
            },
            dataPermission:{
                columns: [],
                rows:[]
            },

            dataRole:{
                columns: [
                    {
                        label: 'Role',
                        field: 'name',
                        sort: true
                    },
                    {
                        label: "",
                        field: "action",
                        sort: true,
                    },
                ],
                rows:[]
            },

            permissionsSelected:[],
            roleSelected:"",
            form:{},
            modalRole: {
                show: false,
                edit: false,
                title: "Ajouter un rôle",
                content: {},
                btn:false
            },
        }
    },

    methods:{
        selectRole(value){
            this.roleSelected = value
            this.permissionsSelected = this.dataRole.rows.filter((item) => item.id === value).map((item) => {
                return item.permissions.map((iperm) => iperm['id'])
            }).at(0)
        },

        setChecking(value) {
            if (this.hasDefaultRole) {
                return
            }
            const index = this.permissionsSelected.indexOf(value);
            if (index !== -1) {
                this.permissionsSelected.splice(index, 1); // Supprime la permission du tableau
            } else {
                this.permissionsSelected.push(value); // Ajoute la permission à la liste des permissions sélectionnées.
            }

            if (this.roleSelected) {
                const role = this.dataRole.rows.filter((item) => item.id === this.roleSelected).at(0)
                this.updatePermissionRole({
                    id: this.roleSelected,
                    name:role.name,
                    permissions:this.permissionsSelected
                })
            }
        },
        
        //Fonction de fonctionnement
        addOrEdit(data = null) {
            if (data instanceof Object) {
                this.modalRole.title = "Modifier un rôle";
                this.modalRole.content = data;
                this.modalRole.edit = true;
                this.formRest(data);
            } else {
                this.modalRole.title = "Ajouter un rôle";
                this.modalRole.content = {};
                this.modalRole.edit = false;
                this.cleanForm("role");
            }

            this.modalRole.show = true;
        },

        cleanForm(type) {
            if (type === 'role') {
				this.form.id = ""
                this.form.name = ""
                this.form.is_active = true
			}
        },

        formRest(data) {
            this.form.id = data.id
            this.form.name = data.name
            this.form.is_active = data.is_active === 1 ? true: false
        },

        // Systeme de datatable
        totalCalcul () {
            return this.dataPagination.pagination.total = this.dataRole.rows.length;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                return new Error('Nothing To Search')
            }
            
             let result = this.dataRole.rows.filter((row) => {
                let isIncluded = false;
                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";
                    if (typeof row[key] === "object") {
                        switch (key) {
                            case "site":  
                                rowValue = (row[key]) ? (row[key].name).toString().toLowerCase() : '';
                                break;
                            default:
                                break;
                        }
                    }

                    if (typeof row[key] === 'string') {
                        rowValue = row[key].toString().toLowerCase();
                    }
                    if (
                        rowValue.includes &&
                        rowValue.includes(this.dataPagination.searchQuery.toLowerCase())
                        ) { 
                        isIncluded = true;
                        }
                }
                return isIncluded;
            });

            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        async loadRoles () {
			this.$nprogress.start()
            this.loading = !this.loading
            await this.$store.dispatch('role/findAll')
            .then(response => {
                this.$nprogress.done()
                this.loading = !this.loading
                if (response.data.roles.length === 0) {
                    this.empty = true
                }else{
                    this.empty = false
                }
                this.dataRole.rows = response.data.roles
            }).catch((error) => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async loadPermissions () {
			this.$nprogress.start()
            this.loading = !this.loading
            await this.$store.dispatch('permission/findAll')
            .then(response => {
                this.$nprogress.done()
                this.loading = !this.loading
                this.dataPermission.rows = response.data.permissions
            }).catch((error) => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async saveRole() {
            this.$store.commit('role/SET_CLEAN')
            this.modalRole.btn = !this.modalRole.btn

            await this.$store.dispatch('role/save', this.form)
			.then((response) => {
				this.modalRole.show = false
                this.modalRole.btn = !this.modalRole.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('role')
                this.loadRoles()
			})
			.catch((error) => {
                this.modalRole.btn = !this.modalRole.btn
                if (error.response.data.errors) { 
                    this.$store.commit('role/SET_NAME', error.response.data.errors.code ?? error.response.data.errors.name ?? '') 
                }

                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },

        async updatePermissionRole(form = null) {
            this.$store.commit('role/SET_CLEAN')
            this.modalRole.btn = !this.modalRole.btn
			await this.$store.dispatch('role/update', form ?? this.form)
			.then(() => {
				this.modalRole.show = false
                this.modalRole.btn = !this.modalRole.btn
				this.cleanForm('role')
                this.loadRoles()
			})
			.catch((error) => {
                this.modalRole.btn = !this.modalRole.btn
                if (error.response.data.errors) {
                    this.$store.commit('role/NAME', error.response.data.errors.name)
                }
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        async updateRole() {
            this.$store.commit('role/SET_CLEAN')
            this.modalRole.btn = !this.modalRole.btn
			await this.$store.dispatch('role/update', this.form)
			.then((response) => {
				this.modalRole.show = false
                this.modalRole.btn = !this.modalRole.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('role')
                this.loadRoles()
			})
			.catch((error) => {
                this.modalRole.btn = !this.modalRole.btn
                if (error.response.data.errors) {
                    this.$store.commit('role/NAME', error.response.data.errors.name)
                }
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        async deleteRole(id){

        let res = await this.$store.dispatch("swal/doYouDelete");
        if (res.isDismissed) {
            return;
        }
        await this.$store.dispatch('role/delete',id)
            .then((response) => {
                this.$notify({
                    message: response.data.message,
                    type: 'success'
                })
                this.loadDepots()
            })
            .catch(error =>{
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })

        },

        nbrPerPageSelected(value){
            let nbr = value;
            
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
           return this.dataPagination.pagination.perPage = nbr
        },
    },

    created(){
        this.loadRoles();
        this.loadPermissions();
    },

    computed: {
        permissionComp () {
            let data =  this.dataRole.rows.map((item) => {
                return item.permissions.map((iperm) => iperm['code'])
            }).flat(1)
            return data
        },
        pageDataRole () {
            return this.dataRole.rows.slice(this.from, this.to);
        },

        to() {
            let highBound = this.queriedDatafrom + this.dataPagination.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },

        queriedData() {
            let result = this.searchQ();
            return (result instanceof Error) ? this.pageDataRole : result;
        },

        from() {
            return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
        },

        total() { 
            return this.totalCalcul();
        },


        hasDefaultRole() { 
            let role = this.dataRole.rows.filter((item) => item.id === this.roleSelected).at(0)
            return role && (role.code === 'ADMIN' || role.code === 'USER');
        },
    }
}
</script>

<style>
.role-view__list
.role-view__list-details{
    width: 100%;
    height: inherit; 

}
.role-view a{
    text-decoration: none;
    color: inherit;
}
.role-view{
    display: grid;
    grid-template-columns: 300px 1fr;
}


.role-view .role-view__list ul{
    height: inherit;
    height: calc(100vh - 88px - 24px - 126px);
    margin: 0;
    padding: 0;
    border-right: 1px solid var(--l-border);
    overflow-x: none;
    overflow-y: auto;
}

.role-view .role-view__list-details{
    height: inherit;
    height: calc(100vh - 88px - 24px - 126px);
    margin: 0;
    padding: 0;
    overflow-x: none;
    overflow-y: auto;
}

#theme-dark .role-view .role-view__list ul{ 
    border-bottom: 1px solid var(--d-border);
}

.role-view .role-view__list ul li:first-child{
    /* border-bottom: 1px solid var(--l-border); */
}

#theme-dark .role-view .role-view__list ul li:first-child{
    border-right: 1px solid var(--d-border);
}

.role-view .role-view__list ul li{
    list-style-type: none;
    transition: all .2s;
    padding: 11.5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.role-view .role-view__list ul li i{
    opacity: 0;
}

.role-view .role-view__list ul li a{
    text-decoration: none;
    color: inherit;
    transition: all .2s;
}


#theme-dark .role-view .role-view__list ul li:hover,
#theme-dark .role-view .role-view__list ul li.is-active{
    background: var(--d-primary);
    cursor: pointer;
    color: unset;
}

.role-view .role-view__list ul li:hover,
.role-view .role-view__list ul li.is-active{
    background: var(--l-selection-bg);
    cursor: pointer;
    color: var(--white);
}

.role-view .role-view__list ul li:hover i,
.role-view .role-view__list ul li.is-active  i{
    opacity: 1;
}


.role-view__list-details table{
    width: 100%;
    /* background: #000; */
}
.role-view__list-details table tr td{
    padding: 11px 20px;
    border-bottom: 1px solid var(--l-border);
}
.role-view__list-details table tr td > label.el-checkbox{
    margin-bottom: 0;
    font-family: "Roboto", "Arial", sans-serif;
}
.role-view__list-details table tr td.labelbox {    left: 0;

    font-weight: 400;
}

.role-view__list-details table tr td.checkingbox{
    width: 50px !important;
}

#theme-dark .role-view__list-details table tr td{
    padding: 11px 20px;
    border-bottom: 1px solid var(--d-border);
}

#theme-dark .role-view__list-details table tr td > label.el-checkbox .el-checkbox__input:not(.is-checked) .el-checkbox__inner {
    background: transparent;
    background-color: transparent;
    border-color: var(--d-border);
}

</style>

<style>
 /* Customize the label (the custom-checkbox) */
 .custom-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;    left: 0;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid #cecece;
    border-radius: 2px;
  }
  
  /* On mouse-over, add a grey background color */
  .custom-checkbox:hover input ~ .checkmark {
    background-color: #fff;
    border: 1px solid #cecece;
  }
  
  /* When the checkbox is checked, add a blue background */
  .custom-checkbox input:checked ~ .checkmark {
    background-color:var(--primary);
    border: 1px solid var(--primary);
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .custom-checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .custom-checkbox .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  } 
</style>

<style lang="scss" scoped>
.table-dh56265656{
    width: inherit;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    z-index: 200;
}

#theme-dark{
    .table-dh56265656{
        background: var(--d-card-bg);
    }

    .role-view .role-view__list ul {
        border-right: 1px solid var(--d-border);
      }
}
</style>