export default {
  production_tip: true,
  tba_server_dev: "http://tbaapi.test/",
  // tba_server_prod:"https://btba.tec-sarl.com/",
  tba_server_prod: "https://tba.tec-sarl.com/",
  tba_sp_dev: "http://localhost:5173/",
  tba_sp_prod: "https://salepoint.tecbill.net/",
  cookie_secure: false,
  theme_interface: {
    chart: {
      light: {
        color: "#d7dee9",
        bgcolor: "rgba(27, 42, 102, 0.212)",
        fv: {
          bgcolor: "rgba(76, 175, 80, 0.2)",
          borderColor: "rgba(76, 175, 80, 1)",
        },
        fa: {
          bgcolor: "rgba(156, 39, 176, 0.2)",
          borderColor: "rgba(156, 39, 176, 1)",
        },
        ev: {
          bgcolor: "rgba(233, 30, 99, 0.2) ",
          borderColor: "rgba(233, 30, 99, 1)",
        },
        ea: {
          bgcolor: "rgba(255, 152, 0, 0.2)",
          borderColor: "rgba(255, 152, 0, 1)",
        },
      },
      dark: {
        color: "#000",
        bgcolor: "rgba(233, 30, 99, 0.2) ",
        fv: {
          bgcolor: "rgba(0, 255, 8, 0.1)",
          borderColor: "rgba(24, 248, 31,0.8)",
        },
        fa: {
          bgcolor: "rgb(27, 114, 255,0.2)",
          borderColor: "rgba(27, 114, 255,0.8)",
        },
        ev: {
          bgcolor: "rgba(233, 30, 99, 0.2) ",
          borderColor: "rgba(233, 30, 99, 1)",
        },
        ea: {
          bgcolor: "rgba(255, 152, 0, 0.2)",
          borderColor: "rgba(255, 152, 0, 1)",
        },
      },
    },
  },
};
